import { getServerUrl } from '../appConf';
import { checkStatus } from '../utils/fetch';

export function getRecommendedProducts(partnerID, userID, options, testing = false, environment = 'test') {
  const serverUrl = getServerUrl(testing, environment);
  if (!partnerID) {
    console.error('ParnterID is mandatory to get recommended products');
    return null;
  }
  if (!userID) {
    console.error('userID is mandatory to get recommended products');
    return null;
  }

  options.partner_id = partnerID;
  options.user_id = userID;

  const url = serverUrl + 'api/functions/getRecommendedProducts/';
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(options),
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': 'Revieve API',
    },
  })
    .then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      console.error('Request failed', error);
      return new Error(error);
    });
}

export const getProduct = ({ partnerId, productId, productGTIN, testing = false, environment = 'test' }) => {
  const serverUrl = getServerUrl(testing, environment);
  if (!partnerId) {
    console.error('PartnerId is mandatory to get recommended products');
    return null;
  }
  if (!productId && !productGTIN) {
    console.error('ProductId or productGtin is mandatory to get recommended products');
    return null;
  }

  const options = {
    partner_id: partnerId,
    product_id: productId,
    product_gtin: productGTIN,
  };

  const url = serverUrl + 'api/functions/getProduct/';
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(options),
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': 'Revieve API',
    },
  })
    .then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      console.error('Request failed', error);
      return new Error(error);
    });
};

export const getProductWithVariants = ({
  partnerId,
  productId,
  productGTIN,
  testing = false,
  environment = 'test',
}) => {
  const serverUrl = getServerUrl(testing, environment);
  if (!partnerId) {
    console.error('PartnerId is mandatory to get recommended products');
    return null;
  }
  if (!productId && !productGTIN) {
    console.error('ProductId or productGtin is mandatory to get recommended products');
    return null;
  }

  const options = {
    partner_id: partnerId,
    product_id: productId,
    product_gtin: productGTIN,
  };

  const url = serverUrl + 'api/functions/getProductWithVariants/';
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(options),
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': 'Revieve API',
    },
  })
    .then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      console.error('Request failed', error);
      return new Error(error);
    });
};

export const getProducts = ({ partnerId, productIds, testing = false, environment = 'test' }) => {
  const serverUrl = getServerUrl(testing, environment);
  if (!partnerId) {
    console.error('PartnerId is mandatory to get products');
    return null;
  }
  if (!productIds) {
    console.error('ProductIds is mandatory to get products');
    return null;
  }
  if (!Array.isArray(productIds)) {
    console.error('ProductIds should be an array');
    return null;
  }

  const options = {
    partner_id: partnerId,
    product_ids: productIds,
  };

  const url = serverUrl + 'api/functions/getProducts/';
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(options),
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': 'Revieve API',
    },
  })
    .then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      console.error('Request failed', error);
      return new Error(error);
    });
};

export const getProductsWithScores = (
  partnerID,
  userID,
  options,
  testing = false,
  environment = 'test',
  productIds,
) => {
  const serverUrl = getServerUrl(testing, environment);
  if (!partnerID) {
    console.error('ParnterID is mandatory to get recommended products');
    return null;
  }
  if (!userID) {
    console.error('userID is mandatory to get recommended products');
    return null;
  }

  options.partner_id = partnerID;
  options.user_id = userID;
  // don't include product_ids into original options object (mutable)
  const body = Object.assign({}, options, { product_ids: productIds });

  const url = serverUrl + 'api/functions/getProductsWithScores';
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': 'Revieve API',
    },
  })
    .then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      console.error('Request failed', error);
      return new Error(error);
    });
};

export const getAlternativeProducts = (
  partnerID,
  userID,
  options,
  testing = false,
  environment = 'test',
  productId,
  max,
  categories,
  excludeProductIds,
) => {
  const serverUrl = getServerUrl(testing, environment);
  if (!partnerID) {
    console.error('parnterID is mandatory to get recommended alternative products');
    return null;
  }
  if (!userID) {
    console.error('userID is mandatory to get recommended alternative products');
    return null;
  }
  if (!productId) {
    console.error('productId is mandatory to get recommended alternative products');
    return null;
  }

  options.partner_id = partnerID;
  options.user_id = userID;
  options.options = {
    categories,
  };

  const localOptions = {
    product_id: productId,
    max,
    exclude_product_ids:
      excludeProductIds && Array.isArray(excludeProductIds) && excludeProductIds.length > 0
        ? excludeProductIds
        : undefined,
  };
  // don't include local options into original options object (mutable)
  const body = Object.assign({}, options, localOptions);

  const url = serverUrl + 'api/functions/getAlternativeProducts';
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'X-Parse-Application-Id': 'Revieve API',
    },
  })
    .then(checkStatus)
    .then(response => response.json())
    .catch(error => {
      console.error('Request failed', error);
      return new Error(error);
    });
};
